<template>
    <div v-if="isViewNotifications">
        <b-card no-body>
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    </b-col>
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" @input="fetchThemes" class="d-inline-block mr-1"
                                placeholder="Search..." />
                            <AddNotifications :manage="isAddNotifications" :token="bearerToken" @fetchThemes="fetchThemes" />
                        </div>
                    </b-col>
                </b-row>
            </div>
            <Notification :show="show" :themes="themes.data || []" :manage="isAddNotifications" :token="bearerToken"
                @fetchThemes="fetchThemes" />
            <div class="mx-2 mt-1 mb-1">
                <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
                    <div class="dataTables_info mt-1"></div>
                    <pagination class="mt-1 mb-0" :data="themes" :limit=2 @pagination-change-page="fetchThemes">
                    </pagination>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BBreadcrumb
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        Notification: () => import("@/views/components/notifications/Notification.vue"),
        AddNotifications: () => import("@/views/components/notifications/AddNotification.vue"),
        ToastificationContent,
        BBreadcrumb,
        BCard,
        BRow,
        BCol,
        BFormInput,
    },
    data() {
        return {
            isAddNotifications: false,
            searchQuery: "",
            show: false,
            alertMessageToast: "",
            bearerToken: "",
            themes: [],
            isViewNotifications: false,
        };
    },
    mounted() {
        this.isAddNotifications = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "add_app_notifications");
        this.isViewNotifications = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "view_app_notifications");
        this.bearerToken = "Bearer " + localStorage.getItem("_t")
        this.fetchThemes();
    },
    methods: {
        async fetchThemes(page = 1) {
            this.show = true;
            const payload = {};
            payload.search = this.searchQuery;
            try {
                const res = await axios.post(process.env.VUE_APP_API_URL + `/themes?page=${page}`, payload, {
                    headers: {
                        Authorization: this.bearerToken,
                    },
                });
                this.themes = res.data.themes;
                this.show = false;
            } catch (error) {

            }
        },
    },
};
</script>
